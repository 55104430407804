@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://cdn.appfoliowebsites.com/sites/resources/css/appfolio-fontawesome-icons.css');
@import 'Bootstrap.scss';
@import '~bootstrap/scss/bootstrap';

// Custom styles
html {
	font-size: 14px;
}

#root {
	overflow: hidden;
}

.btn:not(.default-style) {
	min-width: 160px;
}

.btn:not(.default-style) > i {
	opacity: 0.6;
	margin: 0 0.25rem;
}

a:not(.btn) {
	text-decoration: none;

	&:hover,
	&:focus-within {
		text-decoration: underline;
	}
}

p {
	word-break: break-word;
	margin-bottom: 0.75rem !important;
}

hr {
	opacity: 1;
	color: var(--bs-light);
	margin: 2rem 0;
}

.user-profile-image {
	border-radius: 50%;
	margin-right: 1rem;
	background: rgba(0, 0, 0, 0.1);
	font-size: 0;
	flex: 0 0 auto;
}

.hover-edit {
	transition: 0.1s ease;
	display: inline-block;
}

.hover-edit:hover {
	cursor: pointer;
	opacity: 0.5;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.form-text {
	display: block;
	margin: 0.25rem 0 0.5rem;
	color: var(--bs-secondary);
}

@media (max-width: 576px) {
	html {
		font-size: 13px;
	}

	.btn:not(.default-style) {
		display: block !important;
		width: 100% !important;
		margin: 0 0 0.75rem !important;
	}

	.mobile-hide {
		display: none !important;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes flash {
	0% {
		background: var(--border);
	}
	50% {
		background: white;
	}
}
